import { useState, useEffect } from "react";
import Responsive from "../../Components/Responsive";

import axios from "axios";
import moment from "moment";
import {
  Button,
  DatePicker,
  DateRangePicker,
  Input,
  SelectPicker,
} from "rsuite";

// import { PaymentData } from "../atoms/Hotel/PaymentData";

import SearchIcon from "@rsuite/icons/Search";
// import { usePathname, useRouter } from "next/navigation";
// import {  } from "react";
import { useRecoilState } from "recoil";
import Chambres from "./Chambres";
import { Rooms } from "../../Atoms/Rooms";

const Hotelsearch = ({
  handleSubmit,
  destination_code,
  setdestination_code,
  setOpenLoadingModal,
  setLoading,
}) => {
  // const pathname = usePathname();
  // const route = useRouter();
  const [check, setcheck] = useState([
    new Date(),
    new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
  ]);
  const [checkIn, setcheckIn] = useState(new Date());
  const [checkOut, setcheckOut] = useState(
    new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
  );
  const [cities, setcities] = useState([]);
  const [rooms, setrooms] = useState([
    { NbChamber: 1, NbAdult: 1, NbEnfant: 0, ChildAges: [] },
  ]);

  const [Adults_Total, setAdults_Total] = useState(1);
  const [childs_Total, setchilds_Total] = useState(0);
  // const [Payment_data, setPayment_data] = useRecoilState(PaymentData);
  const [ShowDetails, setShowDetails] = useState(false);

  //---------------------Rooms Gloabal For the Sticker------------------------------//
  const [G_Rooms, setG_Rooms] = useRecoilState(Rooms);

  //----------------------------------------------------//
  const getCity = () => {
    axios
      .get(
        "https://hosting.kounouz.travel/Availibility/autocompleteByCountryCode?country=tn"
      )
      .then((res) =>
        setcities(
          res.data.map((item) => ({
            label: item.name,
            value: item.cityId,
          }))
        )
      )
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    let x = 0;
    let y = 0;
    rooms.map((el, index) => {
      x = x + el.NbAdult;
      return x;
    });
    rooms.map((el, index) => {
      y = y + el.NbEnfant;
      return y;
    });
    setAdults_Total(x);
    setchilds_Total(y);
    // setPayment_data((prev) => ({
    //   ...prev,
    //   rooms,
    //   Adults_Total: x,
    //   childs_Total: y,
    // }));
  }, [rooms]);
  // const getData = (model) => {
  //   axios
  //     .post(
  //       "https://hosting.kounouz.travel/Availibility/CheckAvailabilityByProvider",
  //       model
  //     )
  //     .then((res) => {
  //       setHotels(res.data);
  //       // setLoading(false);
  //       // setsearched(true);
  //     })
  //     .catch((err) => console.log(err));
  // };
  useEffect(() => {
    getCity();
  }, []);

  const handleDetails = () => {
    if (ShowDetails == false) setShowDetails(true);
    else setShowDetails(false);
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        backgroundColor: "white",
        padding: 5,
        width: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          gap: 4,
          alignItems: "center",
          width: "100%",
        }}
      >
        <Responsive m={4} x={4} xl={4} className="p-10">
          <label htmlFor="">Destination:</label>
          {cities && (
            <SelectPicker
              block
              value={destination_code}
              data={cities}
              onChange={(v) => setdestination_code(v)}
              placeholder="Gouvernorat"
              className=" w-full "
            />
          )}
        </Responsive>
        <Responsive className="p-10" m={4} x={4} xl={4}>
          <label htmlFor="">Date:</label>
          <DateRangePicker
            block
            value={check}
            onChange={(v) => {
              setcheck(v);
              setcheckIn(v ? moment(v[0]).format("YYYY/MM/DD") : "");
              setcheckOut(v ? moment(v[1]).format("YYYY/MM/DD") : "");
              // setPayment_data((prev) => ({
              //   ...prev,
              //   checkIn: v ? moment(v[0]).format("YYYY/MM/DD") : "",
              //   checkOut: v ? moment(v[1]).format("YYYY/MM/DD") : "",
              // }));
            }}
            className="   "
          />
        </Responsive>

        {/* ----------------------------------------------------------------------------- */}
        <Responsive
          className="p-10"
          m={4}
          x={4}
          xl={4}
          style={{ position: "relative" }}
        >
          <label htmlFor="">Chambres:</label>

          <Input
            className="border-solid  border-gray-200  text-center rounded-lg  text-gray-500 cursor-pointer hover:border-sky-500 inline-block  w-full  "
            readOnly
            style={{
              height: 35,
              paddingTop: 6,
              textAlign: "center",
              width: "100%",
              cursor: "pointer",
            }}
            onClick={() => handleDetails()}
            placeholder={`${rooms.length} Chambres - ${Adults_Total} adultes - ${childs_Total} enfants`}
          />

          {ShowDetails && (
            <div
              className="absolute z-40  w-full md:w-80  md:top-16 md:left-0"
              style={{ position: "absolute", width: "100%" }}
              // onMouseLeave={() => setShowDetails(false)}
            >
              <Chambres rooms={rooms} setrooms={setrooms} />
            </div>
          )}
        </Responsive>
      </div>

      <div className="md:w-1/6 flex mt-5 ">
        <Button
          onClick={() => {
            handleSubmit({
              destination_code,
              checkIn: moment(checkIn).format("YYYY/MM/DD"),
              checkOut: moment(checkOut).format("YYYY/MM/DD"),
              rooms: rooms.map((room, i) => {
                let newRoom = {};
                newRoom["rooms_number"] = room["NbChamber"];
                newRoom["adults_number"] = room["NbAdult"];
                newRoom["child_ages"] = room["ChildAges"];
                return newRoom;
              }),
              provider: "tasnim",
            });
            setG_Rooms(rooms);
            // setOpenLoadingModal(true);
            // setLoading(true);
          }}
          color="yellow"
          appearance="primary"
          endIcon={<SearchIcon />}
          className="md:ml-4 md:w-[120px] w-full"
        >
          Rechercher
        </Button>
      </div>
    </div>
  );
};

export default Hotelsearch;
