import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { Button, Input, Loader, Modal, SelectPicker } from "rsuite";
import { APi } from "../../Api";
import { ENDPOINTS } from "../../Api/enpoints";
import { SaleVoState } from "../../Atoms/SaleVo.atom";
import Responsive from "../../Components/Responsive";
import PriceItem from "./PriceItem";

import Swal from "sweetalert2";
import { AgeType, Gender } from "../../Constants/types";
import SaleVoModel from "../../Models/SaleVoModel";
import { BsDisplay } from "react-icons/bs";

const Trip = () => {
  const [state, setstate] = useState(false);
  const [forcasts, setforcasts] = useState([]);
  const [Packages, setPackages] = useState([]);
  const [clientId, setclientId] = useState(0);
  const [open, setopen] = useState(false);
  const [model, setmodel] = useRecoilState(SaleVoState);
  const [loading, setloading] = useState(false);
  const [error, setError] = useState("");

  const reset = () => {
    setopen(false);
    setmodel(new SaleVoModel());
  };
  const save = () => {
    setstate((prev) => {
      return { ...prev, loading: true };
    });
    let m = {
      ...model,
    };

    APi.createAPIEndpoint(APi.ENDPOINTS.Order + "/VO")
      .create(m)
      .then((res) => {
        setstate((prev) => {
          return { ...prev, loading: false };
        });
        reset();
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Element a été bien ajouté !",
          showConfirmButton: false,
          timer: 1500,
        });
      })
      .catch((e) => {
        setError(e.Message);
        setstate((prev) => {
          return { ...prev, loading: false };
        });
      });
  };
  const fetch = () => {
    APi.createAPIEndpoint(ENDPOINTS.Package)
      .fetch()
      .then((res) => {
        setforcasts([]);
        let c = localStorage.getItem("auth")
          ? JSON.parse(localStorage.getItem("auth")).clientId
          : 0;
        setclientId(c);
        let Packages = res.data.data;
        setPackages(res.data.data);
        APi.createAPIEndpoint(ENDPOINTS.ForcastVo, {
          clientId: c,
          page: 1,
          take: 100,
        })
          .customGet()
          .then((forcasts) => {
            forcasts.data.data.map((el) => {
              let correspondingPackage = Packages.filter(
                (_el) => _el.id == el.packageId
              );
              setforcasts((prev) => [
                ...prev,
                {
                  ...el,
                  name: correspondingPackage[0].name,
                  prices: correspondingPackage[0].prices,
                },
              ]);
            });
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetch();
  }, []);

  return (
    <div>
      <div>
        {forcasts &&
          forcasts.map((el, index) => (
            <div
              className="p-10"
              style={{ display: "inline-block", marginLeft: 5 }}
            >
              <PriceItem
                item={el}
                key={index}
                packages={Packages}
                forcasts={forcasts}
                ident={index}
                clientId={clientId}
                onChoose={(_m) => {
                  let m = { ...model, ..._m };

                  m.clientId = clientId;
                  m.disponibilityId = el.disponibilityId;
                  m.packageId = el.packageId;
                  m.date = new Date();
                  let correspondingPackage = Packages.filter(
                    (el) => el.id == m.packageId
                  );

                  m.hotelName1 = correspondingPackage[0].packageProviders
                    .filter((el) => el.hotels)[0]
                    .hotels.split(",")
                    .filter((el) => el != "test")[0];

                  setmodel(m);
                  setopen(true);
                }}
              />
            </div>
          ))}
      </div>
      <Modal
        style={{ maxHeight: "95vh", overflow: "auto" }}
        size="lg"
        overflow={false}
        open={open}
        onClose={() => {
          setopen(false);
          setmodel(new SaleVoModel());
          setError("");
        }}
      >
        <Modal.Header>
          <Modal.Title>Ajouter Vente</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <Responsive m={6} l={5} xl={5} className="p-10">
              <label>Nom Complet:</label>
              <Input
                value={model.customer.name}
                onChange={(name) => {
                  let customer = { ...model.customer };
                  customer.name = name;
                  setmodel({ ...model, customer });
                }}
              />
            </Responsive>
            <Responsive m={6} l={5} xl={5} className="p-10">
              <label>Tél:</label>
              <Input
                type="phone"
                value={model.customer.phoneNumber}
                onChange={(phoneNumber) => {
                  let customer = { ...model.customer };

                  customer.phoneNumber = phoneNumber;
                  setmodel({ ...model, customer });
                }}
              />
            </Responsive>
            <Responsive m={6} l={5} xl={5} className="p-10">
              <label>N° Passport:</label>
              <Input
                value={model.customer.passportNumber}
                onChange={(passportNumber) => {
                  let customer = { ...model.customer };

                  customer.passportNumber = passportNumber;
                  setmodel({ ...model, customer });
                }}
              />
            </Responsive>
            <Responsive s={10} m={4.4} l={2} xl={2} className="p-10">
              <label>Date d'expiration :</label>
              <Input
                type="date"
                value={model.customer.expirationPassportDate}
                onChange={(expirationPassportDate) => {
                  let customer = { ...model.customer };

                  customer.expirationPassportDate = expirationPassportDate;
                  setmodel({ ...model, customer });
                }}
              />
            </Responsive>
            <Responsive s={6} m={6} l={2} xl={2} className="p-10">
              <label>Type : </label>
              <SelectPicker
                data={AgeType}
                block
                noSearch
                value={model.customer.ageType}
                onChange={(ageType) => {
                  let customer = { ...model.customer };

                  customer.ageType = ageType;
                  setmodel({ ...model, customer });
                }}
              />
            </Responsive>
            <Responsive s={6} m={6} l={2} xl={2} className="p-10">
              <label>Genre : </label>
              <SelectPicker
                data={Gender}
                block
                noSearch
                value={model.customer.gender}
                onChange={(gender) => {
                  let customer = { ...model.customer };

                  customer.gender = gender;
                  setmodel({ ...model, customer });
                }}
              />
            </Responsive>
            {/* <Responsive s={10} m={4.4} l={2} xl={2} className="p-10">
              <label>Âge:</label>
              <Input
                type="number"
                value={model.customer.age}
                onChange={(age) => {
                  let customer = { ...model.customer };

                  customer.age = parseInt(age);
                  setmodel({ ...model, customer });
                }}
              />
            </Responsive> */}
            <Responsive s={10} m={4.4} l={2} xl={2} className="p-10">
              <label>Date de naissance :</label>
              <Input
                type="date"
                value={model.customer.birthDate}
                onChange={(birthDate) => {
                  let customer = { ...model.customer };

                  customer.birthDate = birthDate;
                  setmodel({ ...model, customer });
                }}
              />
            </Responsive>
          </>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={save} appearance="primary">
            {loading ? <Loader size="sm" /> : "Enregistrer"}
          </Button>
          <Button
            onClick={() => {
              setopen(false);
            }}
            appearance="subtle"
          >
            Annuler
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Trip;
