import axios from "axios";
export const BASE_URL = "https://financeapi.kounouz.travel/";
// export const Local_URL = "http://localhost:5001/";
// export const BASE_URL = "https://localhost:5001/";
const _axios = axios.create({ baseURL: BASE_URL + "api/" });
// export const localAxios = axios.create({ baseURL: Local_URL + "api/" });
_axios.interceptors.response.use(
  function (response) {
    // If the response status is 401, log out the user
    if (response.status === 401) {
      console.log("Unauthorized access. Logging out...");
      // Add your logout logic here (e.g., clearing authentication tokens, redirecting)
      // For demonstration purposes, let's assume a function named logoutUser
      localStorage.removeItem("auth");
      window.location.reload(true);
    }
    return response;
  },
  function (error) {
    // Do something with response error
    return Promise.reject(error);
  }
);
export const AXIOS = _axios;
