import React, { useState } from "react";
import Hotelsearch from "./HotelSearch";
import axios from "axios";
import StickerHotels from "./StickerHotels";

const Hotels = () => {
  const [Hotels, setHotels] = useState([]);
  const [destination_code, setdestination_code] = useState();
  const [openLoadingModal, setOpenLoadingModal] = useState(false);

  const getData = (model) => {
    console.log(model);
    // axios
    //   .post(
    //     "https://hosting.kounouz.travel/Availibility/CheckAvailabilityByProvider",
    //     model
    //   )
    //   .then((res) => {
    //     setHotels(res.data);
    //     // setLoading(false);
    //     // setsearched(true);
    //   })
    //   .catch((err) => console.log(err));
  };
  return (
    <div className="p-10 ">
      <Hotelsearch
        destination_code={destination_code}
        setdestination_code={setdestination_code}
        handleSubmit={getData}
        setOpenLoadingModal={setOpenLoadingModal}
        // setLoading={setLoading}
      />
      <div>{/* <StickerHotels /> */}</div>
    </div>
  );
};

export default Hotels;
