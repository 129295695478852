import CreditCardMinusIcon from "@rsuite/icons/CreditCardMinus";
import CreditCardPlusIcon from "@rsuite/icons/CreditCardPlus";
import GearIcon from "@rsuite/icons/Gear";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Rooming from "./Screens/Rooming";

import {
  Container,
  Content,
  Dropdown,
  Header,
  Nav,
  Sidebar,
  Sidenav,
} from "rsuite";

import PageIcon from "@rsuite/icons/Page";
import { BsMenuButton } from "react-icons/bs";
import { Link, Route, Switch } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";
import { APi } from "./Api";
import { createAPIEndpoint } from "./Api/authenticated.requests";
import { ENDPOINTS } from "./Api/enpoints";
import "./App.scss";
import { isLogged } from "./Atoms/auth.atom";
import { clientAtom } from "./Atoms/client.atom";
import { CurrencyAtom } from "./Atoms/currency.atom";
import { PacksUmrahAtom } from "./Atoms/packs.atom";
import ResetPassword from "./Screens/Auth/ResetPassword";
import Login from "./Screens/Auth/login";
import Badges from "./Screens/Badges";
import PricesOmrah from "./Screens/Prices";
import SaleUmrah from "./Screens/SaleUmrah";
import { PacksVoAtom } from "./Atoms/packsVos.atom";
import SaleVo from "./Screens/SaleVo";
import Passenger from "./Screens/Passenger";
import Trip from "./Screens/pricesVO";
import Hotels from "./Screens/Hotels";
const iconStyles = {
  width: 56,
  height: 56,
  padding: 18,
  lineHeight: "56px",
  textAlign: "center",
};

const App = (props) => {
  const [expand, setExpand] = useState(true);
  const [loaded, setloaded] = useState(false);
  const [active, setactive] = useState(1);
  const [logged, setlogged] = useRecoilState(isLogged);
  const setcurrencies = useSetRecoilState(CurrencyAtom);
  const setPackagesUmrah = useSetRecoilState(PacksUmrahAtom);
  const setPackagesVo = useSetRecoilState(PacksVoAtom);

  const [pageTitle, setpageTitle] = useState("Dashboard");
  const location = useLocation();
  const setclient = useSetRecoilState(clientAtom);
  const fetchPackages = () => {
    APi.createAPIEndpoint(APi.ENDPOINTS.PackageUmrah + "/getForPrices", {})
      .customGet()
      .then((res) => {
        setPackagesUmrah(res.data.data);
      });
  };
  const fetchPackagesVo = () => {
    APi.createAPIEndpoint(APi.ENDPOINTS.Package, {})
      .customGet()
      .then((res) => {
        setPackagesVo(res.data.data);
      });
  };
  useEffect(() => {
    let log = true;
    if (
      !localStorage.getItem("auth") ||
      !JSON.parse(localStorage.getItem("auth")).token
    ) {
      log = false;
    }
    setlogged(log);
    console.log(log);
    setTimeout(() => {
      setloaded(true);
      setpageTitle(routes[location.pathname]);
    }, 1000);

    if (
      localStorage.getItem("auth") &&
      JSON.parse(localStorage.getItem("auth")).clientId
    ) {
      APi.createAPIEndpoint(ENDPOINTS.Client, {})
        .fetchById(JSON.parse(localStorage.getItem("auth")).clientId)
        .then((res) => setclient(res.data));
      APi.createAPIEndpoint(APi.ENDPOINTS.Currency, { page: 1, take: 1000 })
        .fetchAll()
        .then((res) => setcurrencies(res.data.data));
      fetchPackages();
      fetchPackagesVo();
    }
  }, [logged]);
  useEffect(() => {
    setExpand(false);
  }, [pageTitle]);
  useEffect(async () => {
    let _ver = await createAPIEndpoint("version").customGet();
    let version = localStorage.getItem("version");
    console.log(_ver.data);
    if (_ver.data != version) {
      localStorage.setItem("version", _ver.data);
      window.location.reload(true);
    }
  }, [location.pathname]);
  const AuthGard = (Screen) => (logged ? Screen : loaded ? <Login /> : <></>);
  return (
    <div className="app">
      <Container>
        {logged && (
          <Sidebar className={"app-sidebar " + (expand ? "show" : "")}>
            <Sidenav.Header>
              <div className="app-sidebar-header">
                <div>
                  <b style={{ marginLeft: 12, fontSize: "large" }}>
                    UMRAH MANAGEMENT B2B
                  </b>
                </div>
                <button
                  className="close_menu_btn"
                  onClick={(e) => setExpand((prev) => !prev)}
                >
                  x
                </button>
              </div>
            </Sidenav.Header>
            <Sidenav defaultOpenKeys={["1"]} appearance="subtle">
              <Sidenav.Body>
                <Nav>
                  <Nav.Item
                    onClick={() => {
                      setactive("ù");
                      setpageTitle(" Mes Achats Omrah");
                    }}
                    active={active === "ù"}
                    eventKey="ù"
                    icon={
                      <span className="side-span">
                        <CreditCardPlusIcon />
                      </span>
                    }
                  >
                    <Link className={"side_link "} to="/sales_umrah">
                      Mes Achats Omrah
                    </Link>
                  </Nav.Item>
                  <Nav.Item
                    onClick={() => {
                      setactive("ù7");
                      setpageTitle("Les Offres Omrahs");
                    }}
                    active={active === "ù7"}
                    eventKey="ù7"
                    icon={
                      <span className="side-span">
                        <CreditCardPlusIcon />
                      </span>
                    }
                  >
                    <Link className={"side_link "} to="/prices_umrah">
                      Les Offres Omrahs
                    </Link>
                  </Nav.Item>

                  <Nav.Item
                    onClick={() => {
                      setactive("ù32");
                      setpageTitle(" Passager ");
                    }}
                    active={active === "ù32"}
                    eventKey="ù32"
                    icon={
                      <span className="side-span">
                        <CreditCardPlusIcon />
                      </span>
                    }
                  >
                    <Link className={"side_link "} to="/Passenger">
                      Passager
                    </Link>
                  </Nav.Item>

                  <Nav.Item
                    onClick={() => {
                      setactive("5zd5");
                      setpageTitle("Badgdes");
                    }}
                    active={active === "5zd5"}
                    eventKey="5zd5"
                    icon={
                      <span className="side-span">
                        <CreditCardPlusIcon />
                      </span>
                    }
                  >
                    <Link className={"side_link "} to="/badges">
                      Badgdes
                    </Link>
                  </Nav.Item>
                  <Nav.Item
                    onClick={() => {
                      setactive("rooming");
                      setpageTitle("Rooming");
                    }}
                    active={active == "rooming"}
                    eventKey="rooming"
                    icon={
                      <span className="side-span">
                        <CreditCardPlusIcon />
                      </span>
                    }
                  >
                    <Link className={"side_link "} to="/rooming">
                      Rooming
                    </Link>
                  </Nav.Item>
                  <Nav.Item
                    onClick={() => {
                      setactive("2");
                      setpageTitle("Mes Achats");
                    }}
                    active={active === "2"}
                    eventKey="2"
                    icon={
                      <span className="side-span">
                        <CreditCardPlusIcon />
                      </span>
                    }
                  >
                    <Link className={"side_link "} to="/sales_umrah">
                      Mes Achats
                    </Link>
                  </Nav.Item>
                  <Nav.Item
                    onClick={() => {
                      setactive("22");
                      setpageTitle("Mes Achats Voyages Organisées");
                    }}
                    active={active === "22"}
                    eventKey="22"
                    icon={
                      <span className="side-span">
                        <CreditCardPlusIcon />
                      </span>
                    }
                  >
                    <Link className={"side_link "} to="/sales_vos">
                      Mes Achats VOs
                    </Link>
                  </Nav.Item>
                  <Nav.Item
                    onClick={() => {
                      setactive("222");
                      setpageTitle("Les Offres Voyages Organisées");
                    }}
                    active={active === "222"}
                    eventKey="222"
                    icon={
                      <span className="side-span">
                        <CreditCardPlusIcon />
                      </span>
                    }
                  >
                    <Link className={"side_link "} to="/trips">
                      Les Offres VOs
                    </Link>
                  </Nav.Item>
                  <Nav.Item
                    onClick={() => {
                      setactive("565");
                      setpageTitle("Hôtellerie");
                    }}
                    active={active === "565"}
                    eventKey="565"
                    icon={
                      <span className="side-span">
                        <CreditCardPlusIcon />
                      </span>
                    }
                  >
                    <Link className={"side_link "} to="/hotels">
                      Hôtellerie
                    </Link>
                  </Nav.Item>

                  <Nav.Item
                    onClick={() => {
                      setactive("11");
                      setpageTitle(" Mon Grand Livre ");
                    }}
                    active={active === "11"}
                    eventKey="11"
                    icon={
                      <span className="side-span">
                        <CreditCardMinusIcon />
                      </span>
                    }
                  >
                    <Link className={"side_link "} to="/sales_umrah">
                      Mon Grand Livre
                    </Link>
                  </Nav.Item>

                  <Nav.Item
                    eventKey="10"
                    icon={
                      <span className="side-span">
                        <PageIcon />
                      </span>
                    }
                    onClick={() => {
                      setactive("10");
                      setpageTitle(" Mes recouvrements");
                    }}
                    active={active === "10"}
                  >
                    <Link className={"side_link "} to="/sales_umrah">
                      Mes recouvrements
                    </Link>
                  </Nav.Item>

                  <Nav.Item
                    eventKey="14"
                    icon={
                      <span className="side-span">
                        <PageIcon />
                      </span>
                    }
                    onClick={() => {
                      setactive("14");
                      setpageTitle("Garanties Clients");
                    }}
                    active={active === "14"}
                  >
                    <Link className={"side_link "} to="/sales_umrah">
                      Garanties Clients
                    </Link>
                  </Nav.Item>
                  <Nav.Item
                    eventKey="1777"
                    icon={
                      <span className="side-span">
                        <PageIcon />
                      </span>
                    }
                    onClick={() => {
                      setactive("1777");
                      setpageTitle("Changer mot de passe");
                    }}
                    active={active === "1777"}
                  >
                    <Link className={"side_link "} to="/reset_password">
                      Changer mot de passe
                    </Link>
                  </Nav.Item>
                </Nav>
              </Sidenav.Body>
            </Sidenav>
          </Sidebar>
        )}

        <Container className={"hole-container"}>
          {logged && (
            <Header className="page-header">
              <h4>{pageTitle}</h4>{" "}
              <div
                style={{
                  display: "inline-flex",
                }}
              >
                <button
                  className="menu_btn"
                  onClick={(e) => setExpand((prev) => !prev)}
                >
                  <BsMenuButton />
                </button>

                <Nav>
                  <Dropdown
                    placement="bottomEnd"
                    trigger="click"
                    icon={<GearIcon />}
                    renderTitle={(children) => {
                      return <GearIcon style={iconStyles} />;
                    }}
                  >
                    <Dropdown.Item>Help</Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        localStorage.removeItem("auth");
                        setlogged(false);
                      }}
                    >
                      Sign out
                    </Dropdown.Item>
                  </Dropdown>
                </Nav>
              </div>
            </Header>
          )}

          <Content className={"app-content " + (!logged ? "" : "logged")}>
            {/* {!loaded && (
              <div
                style={{
                  position: "fixed",
                  zIndex: "10000",
                  top: 0,
                  bottom: 0,
                  right: 0,
                  left: 0,
                  background: "#fff",
                }}
              ></div>
            )} */}
            <Switch>
              <Route
                path="/reset_password"
                render={(props) => AuthGard(<ResetPassword {...props} />)}
              />
              <Route
                path="/rooming"
                render={(props) => AuthGard(<Rooming {...props} />)}
              />
              <Route
                path="/badges"
                render={(props) => AuthGard(<Badges {...props} />)}
              />
              <Route
                path="/sales_umrah"
                render={(props) => AuthGard(<SaleUmrah {...props} />)}
              />
              <Route
                path="/sales_vos"
                render={(props) => AuthGard(<SaleVo {...props} />)}
              />
              <Route
                path="/prices_umrah"
                render={(props) => AuthGard(<PricesOmrah {...props} />)}
              />
              <Route
                path="/Passenger"
                render={(props) => AuthGard(<Passenger {...props} />)}
              />
              <Route
                path="/trips"
                render={(props) => AuthGard(<Trip {...props} />)}
              />
              <Route
                path="/hotels"
                render={(props) => AuthGard(<Hotels {...props} />)}
              />
              <Route
                path="/*"
                render={(props) => AuthGard(<SaleUmrah {...props} />)}
              />
            </Switch>
          </Content>
        </Container>
      </Container>
    </div>
  );
};

export default App;

const routes = {
  "/sales": "Ventes",
  "/expense": "Dépenses",
  "/tiers/creditors": "Créditeurs",
  "/tiers/providers": "Fournisseurs",
  "/tiers/clients": "Clients",
  "/checks": " Gestion des chéques",
  "/settings/employee_position": " Postes des employés",
  "/settings/services": " Types Des Services",
  "/settings/currencies": "Devises",
  "/treasory/banks": "Banques",
  "/treasory/checkouts": "Caisses",
  "/invoices": "Factures",
  "/invoices/settings": "Paramétrage Facture",
};
