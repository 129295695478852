"use client";
import React, { useEffect } from "react";
import Number from "./Number";

function Chambres({ rooms, setrooms }) {
  return (
    <div
      style={{
        padding: 2,
        backgroundColor: "white",
        borderRadius: 15,
        boxShadow:
          "0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "0 8px",
          margin: "10px 0",
          width: "100%",
        }}
      >
        <span style={{ textAlign: "center", fontWeight: "bold", fontSize: 18 }}>
          Nombre de Chambre:
        </span>
        <Number
          max={30}
          min={1}
          value={rooms.length}
          setValue={(v) => {
            setrooms((prev) => {
              if (v > prev.length) {
                let Room = [...prev];
                return Room.concat({
                  NbChamber: v,
                  NbAdult: 1,
                  NbEnfant: 0,
                  ChildAges: [],
                });
              } else {
                return prev.slice(0, v);
              }
            });
          }}
        />
      </div>
      <hr
        width="80%"
        color=""
        style={{ borderTop: "1px dotted gray", margin: "auto" }}
      />
      {rooms.map((el, i) => {
        return (
          <div key={i}>
            <div
              className="text-center mt-3 font-semibold text-lg"
              style={{
                textAlign: "center",
                marginTop: 12,
              }}
            >
              <span style={{ fontWeight: "bold", fontSize: 18 }}>
                Chambre N°{el.NbChamber}:
              </span>
            </div>
            <div
              className="flex items-center justify-between p-1 mt-2  px-2"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "2px 4px",
                marginTop: 4,
              }}
            >
              <span
                className=" text-base font-semibold  w-full "
                style={{ fontSize: 16, fontWeight: "bold" }}
              >
                Adults:
              </span>
              <Number
                max={5}
                min={1}
                value={el.NbAdult}
                setValue={(v) => {
                  setrooms((prev) => {
                    let res = [...prev];
                    let room = { ...res[i] };
                    room.NbAdult = v;
                    res[i] = room;
                    return res;
                  });
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "2px 4px",
                marginTop: 4,
              }}
            >
              <span style={{ fontSize: 16, fontWeight: "bold" }}>Enfants:</span>
              <Number
                max={5}
                value={el.NbEnfant}
                setValue={(v) => {
                  setrooms((prev) => {
                    let res = [...prev];
                    let room = { ...res[i] };
                    room.NbEnfant = v;
                    if (v > room.ChildAges.length) {
                      room.ChildAges = room.ChildAges.concat(
                        new Array(v - room.ChildAges.length).fill(1)
                      );
                    } else {
                      room.ChildAges = room.ChildAges.slice(0, v);
                    }
                    res[i] = room;
                    return res;
                  });
                }}
              />
            </div>
            {el.ChildAges.map((u, j) => {
              return (
                <div
                  className="flex items-center justify-between p-1 mt-2 mb-1 px-2 "
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "2px 4px",
                    margin: "3px 0px",
                  }}
                  key={j}
                >
                  <span
                    className=" text-base text-center mr-3"
                    style={{
                      textAlign: "center",
                      marginRight: 12,
                      fontSize: 16,
                    }}
                  >
                    Age de l'enfant N° {j + 1}:
                  </span>
                  <Number
                    max={17}
                    min={1}
                    value={u}
                    setValue={(v) =>
                      setrooms((prev) => {
                        let res = [...prev];
                        let room = { ...res[i] };
                        let ages = [...room.ChildAges];
                        ages[j] = v;
                        room.ChildAges = ages;
                        res[i] = room;
                        return res;
                      })
                    }
                  />
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
}

export default Chambres;
