import React from "react";
import { IconButton, InputNumber, InputGroup } from "rsuite";
import PlusIcon from "@rsuite/icons/Plus";
import MinusIcon from "@rsuite/icons/Minus";

const Number = ({ max, min = 0, value = 0, setValue }) => {
  const handleMinus = () => {
    setValue(value - 1);
  };
  const handlePlus = () => {
    setValue(value + 1);
  };

  return (
    <div style={{ display: "flex", position: "relative" }}>
      <IconButton
        icon={<MinusIcon />}
        onClick={handleMinus}
        disabled={value == min ? true : false}
      />

      <span
        className=" flex items-center justify-center w-10"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: 20,
          fontSize: 17,
        }}
      >
        {value}
      </span>
      <IconButton
        icon={<PlusIcon />}
        onClick={handlePlus}
        disabled={value == max ? true : false}
      />
    </div>
  );
};

export default Number;
